body {
  color: #002766;
}

.lpc-button-background {
  background-color: #1890ff;
}

.lpc-shadow {
  box-shadow: 9px 9px 23px 1px rgba(0, 0, 0, 0.45);
}

.lpc-light-blue {
  color: #1890ff;
}

.ant-steps-item .ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lpc-search input[type="search"],
.lpc-search .ant-select-selection-placeholder {
  font-size: 16px;
}

.lpc-select .ant-select-selector {
  border-radius: 2px !important;
  font-size: 16px !important;
}

.ant-select-item-option-content {
  font-size: 16px !important;
}

.lpc-track-retailers-button button[disabled] {
  border-radius: 2px;
  height: 42px;
  display: flex;
  align-items: center;
}

.lpc-track-retailers-button button span.anticon {
  vertical-align: 0em !important;
}

.lpc-icon .anticon {
  vertical-align: baseline;
  display: flex;
  align-items: center;
}

.lpc-validation-errors {
  list-style-type: disc;
}

.lpc-list li {
  list-style: disc;
}

ul.lpc-list {
  margin-left: 20px;
}

.ant-steps-item-title {
  color: #ffffff !important;
}

.ant-steps-vertical {
  height: 40% !important;
}

.onboarding-steps-bar {
  background-color: #0071bc;
}

.ant-steps-item-tail::after {
  background-color: white !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #0071bc;
  border-color: white !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: white !important;
  border-color: #0071bc;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #0071bc;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #0071bc;
  border-color: white !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
